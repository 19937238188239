.divider {
  height: 1px;
  width: 100%;
  background: #D0D0D0;
  margin: 16px 0;
}

@media screen and (max-width: 600px) {
  .divider {
    margin: 8px 0;
  }
}